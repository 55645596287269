/* stylelint-disable */
	/* Generic table content */
	.rs-table-hover .rs-table-row:hover .rs-table-cell, .rs-table-hover .rs-table-row:hover .rs-table-cell-group, .rs-table-row-expanded, .rs-table-row-header, .rs-table-row-header .rs-table-cell, .rs-table-cell {
		background-color: transparent !important;
	}

	.rs-table-cell-content {
		font-family: 'Roobert', sans-serif;
		font-size: 14px !important;
		background-color: transparent !important;
	}

	/* Generic table header */
	.rs-table-cell-header {
		.rs-table-cell-content {
			text-transform: uppercase;
			font-family: 'Roobert', sans-serif;
			font-size: 12px !important;
			font-weight: 700 !important;
		}
	}

	// header section
	.rs-table-row-header {
		border-radius: 3px;
	}

	// body section
	.rs-table-body-row-wrapper {
		.rs-table-body-wheel-area {
			.rs-table-row {
				&:nth-child(2n) {
					.rs-table-cell-content {
						font-weight: 500;
					}
				}

				&:nth-child(2n + 1) {
					.rs-table-cell-content {
						font-weight: 500;
					}
				}
			}
		}
	}
